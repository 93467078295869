<template>
  <div class="blogsec">
    <div class="blogsec-wrapper">
      <div class="blogsec-meta mb-2">
        <!-- <h1 class="mb-1">{{ sectionName }}</h1> -->
        <b-skeleton animation="wave" height="20px" width="20%"></b-skeleton>
      </div>
      <div class="blog-article-list">
        <div v-for="(item, index) in 2" :key="index">
          <b-card no-body class="blog-article">
            <b-skeleton
              animation="wave"
              height="100%"
              width="100%"
            ></b-skeleton>
          </b-card>
        </div>
      </div>
      <b-button
        variant="outline-primary"
        class="align-items-center mt-2 seemore-bottom"
        >Voir plus
      </b-button>
    </div>
  </div>
</template>
<script>
import { BBadge, BButton, BCard, BImg, BSkeleton } from 'bootstrap-vue';
import vSelect from 'vue-select';
export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BImg,
    BSkeleton,
    vSelect,
  },
  data() {
    return {
      domainSelected: 'Tout',
      domainOptions: ['Tout', 'Hebergement', 'Tourisme', 'Restauration'],
    };
  },
};
</script>
<style scoped>
.blogsec {
  background-color: #fff;
}
.truncator-two {
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show an ellipsis (...) when text overflows */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to display before truncation */
  -webkit-box-orient: vertical;
  max-height: 2.8em; /* (line height) x (number of lines) */
}
.truncator-three {
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show an ellipsis (...) when text overflows */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to display before truncation */
  -webkit-box-orient: vertical;
  max-height: 4.8em; /* (line height) x (number of lines) */
}
.blogsec-wrapper {
  padding: 3em 3em 4em 3em;
  max-width: 1200px;
  margin: 0 auto;
}
.blogsec-meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blogsec-main-actions {
  display: flex;
  flex-direction: column;
}
.activityFilterText {
  display: none;
}
.activityFilterTextSmall {
  display: inline-block;
}
.seemore-top {
  display: none;
  /* display: flex; */
}
.seemore-bottom {
  display: flex;
}
.blog-article-list {
  display: grid;
  gap: 2em;
  /* grid-template-columns: 1fr 1fr; */
}
.blog-article {
  /* padding: 12px; */
  display: grid;
  height: 208px;
  /* grid-template-columns: 182px 1fr; */
  margin: 0;
  gap: 1em;
  outline: 2px solid rgba(255, 255, 255, 0);
}
.blog-article:hover {
  outline: 7px solid rgba(15, 95, 48, 0.13);
}
.blog-article-image-box {
  isolation: isolate;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  /* width: 182px;
  aspect-ratio: 1/1; */
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 6px;
  overflow: hidden;
  padding: 1em;
  text-align: center;
}
.blog-article-image-overlay {
  position: absolute;
  z-index: -1;
  inset: 0;
  background: linear-gradient(
    0deg,
    rgba(42, 103, 39, 0.7259278711484594) 0%,
    rgba(22, 115, 11, 0.41780462184873945) 18%,
    rgba(0, 212, 255, 0) 46%
  );
}
.blog-article-image-box b {
  color: #fff;
  margin: 0;
  text-shadow: 2px 2px 1px #00000090;
}
.blog-article-image {
  position: absolute;
  inset: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tags-box {
  margin: 6px 0;
  height: 21px;
  overflow: hidden;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .blog-article-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .blogsec-main-actions {
    display: flex;
    margin-left: auto;
    flex-direction: row;
  }
  .seemore-top {
    display: flex;
  }
  .seemore-bottom {
    display: none;
  }
  .blog-article-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .blog-article-list {
    grid-template-columns: 1fr 1fr;
  }
  /* .blog-article {
    grid-template-columns: 182px 1fr;
  } */
  .blog-article-image-box {
    width: 182px;
    aspect-ratio: 1/1;
  }
}
@media (min-width: 1400px) {
}
</style>
