import { BaseService } from "./base.service";

export class PostsService extends BaseService {
  constructor() {
    super();
  }

  fetchPosts(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/posts/search", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addPosts(post) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/posts", post)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  getPosts(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/posts/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchPostsStatus() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/posts/statuses`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchPostsTypes() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/posts/types`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  updatePosts(object) {
    const { id, data } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/posts/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  deletePosts(id) {
    console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/posts/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  fetchCategoryPosts(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/post-categories", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

}

export default new PostsService();
