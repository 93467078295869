import postsService from '@/services/http/blog.service';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Actually a search
    async fetchPosts({ rootState }, data) {
      const res = await postsService.fetchPosts(data);
      return res;
    },
    async fetchPostsTypes({ rootState }, data) {
      const res = await postsService.fetchPostsTypes(data);
      return res;
    },
    async addPosts({ rootState }, data) {
      const res = await postsService.addPosts(data);
      return res;
    },
    async getPosts({ rootState }, data) {
      const res = await postsService.getPosts(data);
      return res;
    },
    async fetchPostsStatus({ rootState }, data) {
      const res = await postsService.fetchPostsStatus(data);
      return res;
    },
    async updatePosts({ rootState }, data) {
      const res = await postsService.updatePosts(data);
      return res;
    },
    async deletePosts({ rootState }, data) {
      const res = await postsService.deletePosts(data);
      return res;
    },
    async fetchCategoryPosts({ rootState }, data) {
      const res = await postsService.fetchCategoryPosts(data);
      return res;
    },
  },
};
